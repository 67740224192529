import { ClaimAdjusterFacade } from "src/modules/claim-register/controller/facades/claim-adjuster.facade";
import { CLAIM_ADJUSTER_PARAMS } from "src/routes/paths/claim-register.paths";
import { Util } from "src/utils/Util";
import { Button, Dropdown, InputArea, Logo, Modal, Status, Text } from "src/components";
import { IconFactory } from "src/factory/icon.factory";
import { Outlet } from "react-router-dom";
import { ClaimPicturesFacade } from "src/modules/claim-register/controller/facades/claim-person-pictures.facade";
import { Controller } from "react-hook-form";
import ClaimPicture from "../../components/ClaimPicture/ClaimPicture";
import ClaimSignature from "../../components/ClaimSignature/ClaimSignature";
import ClaimContent from "../../components/ClaimContent/ClaimContent";
import './ClaimAdjuster.scss';
import { ClaimPersonSyncFacade } from "src/modules/claim-register/controller/facades/claim-person-sync.facade";

/**
 *
 */
const ClaimAdjusterPage = (): JSX.Element => {
  const { sectionName, navigatePage } = ClaimAdjusterFacade.useClaimAdjuster();
  const { adjusterInfo, form, list, states, updating, deleteReason, checkAdjusterConditions, sendCurrentAdjusterInfo, validate } = ClaimAdjusterFacade.useClaimAdjusterForm();
  const { states: syncStates, loading, amisLoading, amisServicesHandler, finishEduaFlow } = ClaimPersonSyncFacade.useClaimPersonSync();
  const referencePictures = ClaimPicturesFacade.useClaimPictures(undefined, adjusterInfo?.referencePictures);
  const duaPictures = ClaimPicturesFacade.useClaimPictures(undefined, adjusterInfo?.duaPicture);

  return (
    <div className="claimAdjuster">

      {
        adjusterInfo &&
        <>
          {
            (sectionName === CLAIM_ADJUSTER_PARAMS.INFO || sectionName === CLAIM_ADJUSTER_PARAMS.INIT) &&
            <ClaimContent
              title={sectionName === CLAIM_ADJUSTER_PARAMS.INFO ? adjusterInfo.claimNumber : ''}
              backButton={sectionName === CLAIM_ADJUSTER_PARAMS.INFO &&
                <button type="button" className="claimRegister__button-back" onClick={() => navigatePage('backward', CLAIM_ADJUSTER_PARAMS.PANEL)}>
                  {IconFactory.leftArrowIcon()}
                </button>
              }
            >
              <div className="claimAdjuster__content">
                <div className="claimAdjuster__content__adjuster-info">
                  {
                    sectionName === CLAIM_ADJUSTER_PARAMS.INIT &&
                    <div className="claimAdjuster__content__adjuster-info__logo">
                      <Logo type="full" />
                      <Text type="title" align="center">{adjusterInfo.claimNumber}</Text>
                    </div>
                  }

                  <div className="claimAdjuster__content__adjuster-info__section">
                    <Text type="subtitle" align="center" bold color="main">Siniestro</Text>

                    <div className="claimAdjuster__content__adjuster-info__section__info">
                      <Text type="paragraph" align="center" bold>Ubicaci&oacute;n</Text>

                      <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="paragraph" align="center">
                        {adjusterInfo.location?.street}, {adjusterInfo.location?.municipality} {adjusterInfo.location?.postalCode}. {adjusterInfo.location?.state}
                      </Text>

                      {
                        adjusterInfo.location?.genericReference &&
                        <>
                          <Text type="paragraph" align="center" bold>Referencia</Text>

                          <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="paragraph" align="center">
                            {adjusterInfo.location?.genericReference}
                          </Text>
                        </>
                      }

                      <a rel="noreferrer" className="claimAdjuster__content__adjuster-info__section__info__link"
                        href={`https://www.google.com/maps/search/?api=1&query=${adjusterInfo.location?.latitude},${adjusterInfo.location?.longitude}`} target="_blank">
                        <Text type="paragraph" color="main" align="center">Abrir en Google Maps</Text>
                      </a>
                    </div>

                    <div className="claimAdjuster__content__adjuster-info__section__info">
                      <Text type="paragraph" align="center" bold>Reportante</Text>

                      <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="paragraph" align="center">
                        {adjusterInfo.reportingName}
                      </Text>
                    </div>

                    <div className="claimAdjuster__content__adjuster-info__section__info">
                      <Text type="paragraph" align="center" bold>Tel&eacute;fono</Text>

                      <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="paragraph" align="center">
                        {adjusterInfo.reportingPhone?.getFullNumber()}
                      </Text>

                      <a className="claimAdjuster__content__adjuster-info__section__info__call"
                        href={`tel:${adjusterInfo.reportingPhone?.getFullNumber()}`}
                      >
                        {IconFactory.phoneIcon()}
                      </a>
                    </div>

                    <div className="claimAdjuster__content__adjuster-info__section__info">
                      <Text type="paragraph" align="center" bold>Ocurrencia</Text>

                      <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="paragraph" align="center">
                        {Util.TRANSFORM.DATE.dateTime(adjusterInfo.date!)}
                      </Text>
                    </div>

                    <div className="claimAdjuster__content__adjuster-info__section__info">
                      <Text type="paragraph" align="center" bold>Narraci&oacute;n a Cabina</Text>

                      <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="paragraph" align="center">
                        {adjusterInfo.cabinFacts}
                      </Text>
                    </div>

                  </div>

                  <div className="claimAdjuster__content__adjuster-info__section">
                    <Text type="subtitle" align="center" bold color="main">Conductor</Text>


                    <div className="claimAdjuster__content__adjuster-info__section__info">
                      <Text type="paragraph" align="center" bold>Nombre</Text>

                      <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="paragraph" align="center">
                        {adjusterInfo.getNaPersonName()}
                      </Text>
                    </div>

                    <div className="claimAdjuster__content__adjuster-info__section__info">
                      <Text type="paragraph" align="center" bold>Veh&iacute;culo</Text>

                      <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="paragraph" align="center">
                        {adjusterInfo.getNaVehicleBrand()} | {adjusterInfo.getNaVehicleModel()} | {adjusterInfo.getNaVehicleYear()} | {adjusterInfo.getNaVehicleColor()}
                      </Text>
                    </div>

                    <div className="claimAdjuster__content__adjuster-info__section__info">
                      <Text type="paragraph" align="center" bold>N&uacute;mero de serie</Text>

                      <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="paragraph" align="center">
                        {adjusterInfo.getNaVehicleVin()}
                      </Text>
                    </div>

                    <div className="claimAdjuster__content__adjuster-info__section__info">
                      <Text type="paragraph" align="center" bold>Placas</Text>

                      <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="paragraph" align="center">
                        {adjusterInfo.getNaVehiclePlates()}
                      </Text>
                    </div>
                  </div>

                  {
                    adjusterInfo.vehicleInvolved &&
                    <div className="claimAdjuster__content__adjuster-info__section">
                      <Text type="subtitle" align="center" bold color="main">Veh&iacute;culo involucrado</Text>

                      <div className="claimAdjuster__content__adjuster-info__section__info">
                        <Text type="paragraph" align="center" bold>Descripci&oacute;n</Text>

                        <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="paragraph" align="center">
                          {adjusterInfo.vehicleInvolved}
                        </Text>
                      </div>
                    </div>
                  }

                  {
                    adjusterInfo.policy &&
                    <div className="claimAdjuster__content__adjuster-info__section">
                      <Text type="subtitle" align="center" bold color="main">P&oacute;liza</Text>

                      <div className="claimAdjuster__content__adjuster-info__section__info">
                        <Text type="paragraph" align="center" bold>N&uacute;mero de P&oacute;liza</Text>

                        <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="paragraph" align="center">
                          {adjusterInfo.policy.policyNumber}
                        </Text>
                      </div>

                      <div className="claimAdjuster__content__adjuster-info__section__info">
                        <Text type="paragraph" align="center" bold>Due&ntilde;o</Text>

                        <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="paragraph" align="center">
                          {adjusterInfo.policy.owner?.getFullName()}
                        </Text>
                      </div>

                      <div className="claimAdjuster__content__adjuster-info__section__info">
                        <Text type="paragraph" align="center" bold>Vigencia</Text>
                        {
                          adjusterInfo.policy.isActive() &&
                          <Status className="claimAdjuster__content__adjuster-info__section__info__policy-status" type="active" shape="background">
                            {adjusterInfo.policy.getStatusValue()}
                          </Status>
                        }
                        {
                          adjusterInfo.policy.isCanceled() &&
                          <Status className="claimAdjuster__content__adjuster-info__section__info__policy-status" type="deactive" shape="background">
                            {adjusterInfo.policy.getStatusValue()}
                          </Status>
                        }
                        {
                          adjusterInfo.policy.isPending() &&
                          <Status className="claimAdjuster__content__adjuster-info__section__info__policy-status" type="pending" shape="background">
                            {adjusterInfo.policy.getStatusValue()}
                          </Status>
                        }
                        {
                          adjusterInfo.policy.toBegin() &&
                          <Status className="claimAdjuster__content__adjuster-info__section__info__policy-status" type="toBegin" shape="background">
                            {adjusterInfo.policy.getStatusValue()}
                          </Status>
                        }
                        {
                          adjusterInfo.policy.isExpired() &&
                          <Status className="claimAdjuster__content__adjuster-info__section__info__policy-status" type="expired" shape="background">
                            {adjusterInfo.policy.getStatusValue()}
                          </Status>
                        }

                        <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="paragraph" align="center">
                          {adjusterInfo.policy.getStartDateFormat()} 
                          <span className="claimAdjuster__content__adjuster-info__section__info__value__icon">{IconFactory.arrowRightLeft()}</span>
                          {adjusterInfo.policy.getExpirationDateFormat()}
                        </Text>
                      </div>

											<div className="claimAdjuster__content__adjuster-info__section__info">
                        <Text type="paragraph" align="center" bold>Valor comercial del vehículo</Text>

                        <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="paragraph" align="center">
													{
														adjusterInfo.hasNaVehicleValue() ?
														<>
															{adjusterInfo.getNaVehicleValue()} <Text type="label">MXN</Text>
														</>
														:
                              !adjusterInfo.isFormStarted ? 'El valor comercial del auto aparecerá luego de marcar arribo.' : '--'
													}
													
                        </Text>
                      </div>

                      <Text type="paragraph" align="center" bold>Coberturas</Text>
                      {
                        adjusterInfo.policy.coverages.map(
                          (coverage, index) => (
                            <div className="claimAdjuster__content__adjuster-info__section__info claimAdjuster__content__adjuster-info__section__info--short-row" key={`coverage-${index}`}>
                              <div className="claimAdjuster__content__adjuster-info__section__info__coverage">
                                <div className="claimAdjuster__content__adjuster-info__section__info__coverage__type">
                                  <span className={`claimAdjuster__content__adjuster-info__section__info__coverage__type__icon 
                                  claimAdjuster__content__adjuster-info__section__info__coverage__type__icon--${coverage.isEnabled ? 'enabled' : 'disabled'}
                                  `}>
                                    {
                                      coverage.isEnabled ?
                                        <>
                                          {IconFactory.checkIcon()}
                                        </>
                                      :
                                        <>
                                          {IconFactory.closeIcon()}
                                        </>
                                    }
                                  </span>
                                  <Text className="claimAdjuster__content__adjuster-info__section__info__coverage__type__text" type="paragraph" align="center" bold>
                                    {coverage.type}
                                  </Text>
                                </div>
                                {
                                  coverage.isDeductible &&
                                  <Text type="label" align="center">Deducible: <b>{coverage.coverageValue()}</b></Text>
                                }
                              </div>

                            </div>
                          )
                        )
                      }
                    </div>
                  }

                  {
                    adjusterInfo.policy && 
                    <div className="claimAdjuster__content__adjuster-info__section">
                      <Text type="subtitle" align="center" bold color="main">Avisos</Text>

                      <div className="claimAdjuster__content__adjuster-info__section__info claimAdjuster__content__adjuster-info__section__info--hasIcon">
                        <div className="claimAdjuster__content__adjuster-info__section__info__image" style={ { backgroundImage: `url(${Util.ASSET.CLAIMS_INFO})` } }></div>
                        <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="label" align="left" bold>
                          {
                            adjusterInfo.policy.policyNumber.includes('IP') ?
                              'Este auto pertenece a una póliza que SÍ cubre autos de plataforma.'
                            :
                              'Este auto pertenece a una póliza que NO cubre autos de plataforma.'
                          }
                        </Text>
                      </div>

                      {
                        adjusterInfo.claimType.isClaimCollision() && adjusterInfo.policy.policyNumber.includes('KV') &&
                          <div className="claimAdjuster__content__adjuster-info__section__info claimAdjuster__content__adjuster-info__section__info--hasIcon">
                            <div className="claimAdjuster__content__adjuster-info__section__info__image" style={ { backgroundImage: `url(${Util.ASSET.CLAIMS_INFO})` } }></div>
                            <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="label" align="left" bold>
                              Este auto pertenece a una póliza que no acepta Pagos de Daños, solo Reparaciones.
                            </Text>
                          </div>
                      }

                      {
                        adjusterInfo.claimType.isClaimCollision() && adjusterInfo.policy.policyNumber.includes('DA') &&
                          <div className="claimAdjuster__content__adjuster-info__section__info claimAdjuster__content__adjuster-info__section__info--hasIcon">
                            <div className="claimAdjuster__content__adjuster-info__section__info__image" style={ { backgroundImage: `url(${Util.ASSET.CLAIMS_INFO})` } }></div>
                            <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="label" align="left" bold>
                              Este auto pertenece a una póliza de Dalton y por lo tanto si se repara tendrá que ser en una Agencia de Dalton.
                            </Text>
                          </div>
                      }

                      {
                        adjusterInfo.claimType.isClaimCollision() && !adjusterInfo.policy.policyNumber.includes('DA') && adjusterInfo.isNewVehicle() &&
                        <div className="claimAdjuster__content__adjuster-info__section__info claimAdjuster__content__adjuster-info__section__info--hasIcon">
                          <div className="claimAdjuster__content__adjuster-info__section__info__image" style={ { backgroundImage: `url(${Util.ASSET.CLAIMS_INFO})` } }></div>
                          <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="label" align="left" bold>
                            Este auto es de una versión de los últimos 2 años. Favor de revisar la fecha de la factura, y en caso de que tenga menos de 2 años y vaya a reparación, mandarlo a Agencia.
                          </Text>
                        </div>
                      }

                      {
                        adjusterInfo.claimType.isClaimCollision() &&
                          <div className="claimAdjuster__content__adjuster-info__section__info claimAdjuster__content__adjuster-info__section__info--hasIcon">
                            <div className="claimAdjuster__content__adjuster-info__section__info__image" style={ { backgroundImage: `url(${Util.ASSET.CLAIMS_INFO})` } }></div>
                            <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="label" align="left" bold>
                              En caso de haber daños a bienes o propiedades y necesitar de contratista, favor de marcar a DAYCO al 5519952977 para el interior de la república, o al ingeniero Barragán al 5624138213 para CDMX y Área Metropolitana.
                            </Text>
                          </div>
                      }

                      {
                        (adjusterInfo.claimType.isClaimCollision() || adjusterInfo.claimType.isClaimTotalLoss()) &&
                          <div className="claimAdjuster__content__adjuster-info__section__info claimAdjuster__content__adjuster-info__section__info--hasIcon">
                            <div className="claimAdjuster__content__adjuster-info__section__info__image" style={ { backgroundImage: `url(${Util.ASSET.CLAIMS_INFO})` } }></div>
                            <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="label" align="left" bold>
                              En caso de necesitar asistencia legal, favor de marcar a AUSA al 5550616293.
                            </Text>
                          </div>
                      }

                      {
                        (adjusterInfo.claimType.isClaimCollision() || adjusterInfo.claimType.isClaimTotalLoss()) &&
                          <div className="claimAdjuster__content__adjuster-info__section__info claimAdjuster__content__adjuster-info__section__info--hasIcon">
                            <div className="claimAdjuster__content__adjuster-info__section__info__image" style={ { backgroundImage: `url(${Util.ASSET.CLAIMS_INFO})` } }></div>
                            <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="label" align="left" bold>
                              En caso de necesitar grúa, favor de marcar a IKE al 5554800951. Si fuera Concesionada, favor de solicitar los datos del proveedor y notificar al coordinador en ese momento que el auto puede ser llevado al corralón.
                            </Text>
                          </div>
                      }

                      {
                        (adjusterInfo.claimType.isClaimCollision() || adjusterInfo.claimType.isClaimTotalLoss()) &&
                          <div className="claimAdjuster__content__adjuster-info__section__info claimAdjuster__content__adjuster-info__section__info--hasIcon">
                            <div className="claimAdjuster__content__adjuster-info__section__info__image" style={ { backgroundImage: `url(${Util.ASSET.CLAIMS_INFO})` } }></div>
                            <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="label" align="left" bold>
                              En caso de siniestro relevante por fractura expuesta o deceso, notificarlo de inmediato al coordinador de siniestros Ezequiel García y/o Alberto Sánchez.
                            </Text>
                          </div>
                      }

                      {
                        adjusterInfo.claimType.isClaimCollision() &&
                          <div className="claimAdjuster__content__adjuster-info__section__info claimAdjuster__content__adjuster-info__section__info--hasIcon">
                            <div className="claimAdjuster__content__adjuster-info__section__info__image" style={ { backgroundImage: `url(${Util.ASSET.CLAIMS_INFO})` } }></div>
                            <Text className="claimAdjuster__content__adjuster-info__section__info__value" type="label" align="left" bold>
                              Recuerda que en Crabi amparamos por el momento estado de ebriedad, y en todos los casos necesitamos licencia física o digital no importando la vigencia.
                            </Text>
                          </div>
                      }                      
                    </div>
                  }
                </div>

                <div className="claimRegister__button-wrapper">
                  {
                    sectionName === CLAIM_ADJUSTER_PARAMS.INIT ?
                      <Button type="button"
                        loading={updating.value}
                        disabled={updating.value}
												onClick={() => {
                          adjusterInfo.isFirstForm && !adjusterInfo.isFormStarted ?
                            states.setShowArrivalModal(true)
                          :
                            sendCurrentAdjusterInfo(false).then(() => navigatePage('forward', CLAIM_ADJUSTER_PARAMS.PANEL))
                        }}
                      >
                        { adjusterInfo.isFirstForm && !adjusterInfo.isFormStarted ? 'Marcar arribo' : 'Continuar' }
                      </Button>
                    :
                      <Button type="button"
                        onClick={() => navigatePage('backward', CLAIM_ADJUSTER_PARAMS.PANEL)}
                      >
                        Volver
                      </Button>
                  }

                </div>

								<Modal show={states.showArrivalModal} position="center">
									<div className="claimAdjuster__arrivalModal">
										<Text type="subtitle" align="center">Al marcar arribo estás confirmando que te encuentras con el Asegurado, ¿Deseas continuar?</Text>
										
										<div className="claimAdjuster__arrivalModal__buttonsWrapper">
											<Button 
												type="button" 
												loading={updating.value}
                        disabled={updating.value}
												onClick={() => sendCurrentAdjusterInfo(false).then(() => { states.setShowArrivalModal(false); navigatePage('forward', CLAIM_ADJUSTER_PARAMS.PANEL) })}
											>
												Marcar arribo
											</Button>

											<Button 
												fill="outline"
												disabled={updating.value}
												onClick={() => states.setShowArrivalModal(false)}
											>
												Cancelar
											</Button>
										</div>
									</div>
								</Modal>
              </div>
            </ClaimContent>
          }

          {
            sectionName === CLAIM_ADJUSTER_PARAMS.PANEL &&
            <ClaimContent
              title="Panel principal"
              subtitle="Completa estas tres secciones para poder finalizar el formulario"
            >
              <div className="claimAdjuster__content">
                <div className="claimRegister__data-panel">
                  <div className="claimRegister__data-panel__section"
                    onClick={() => navigatePage('forward', CLAIM_ADJUSTER_PARAMS.DECLARATION)}
                  >
                    <div className="claimRegister__data-panel__section__icon">
                      <span className="claimRegister__data-panel__section__icon__bg" style={{ backgroundImage: `url(${Util.ASSET.CLAIMS_PANEL_LICENSE})` }}></span>
                    </div>

                    <div className="claimRegister__data-panel__section__title">
                      <Text type="label" block>Declaraci&oacute;n y</Text>
                      <Text type="paragraph" bold>Causas del siniestro</Text>
                    </div>

                    <div className="claimRegister__data-panel__section__arrow">
                      {IconFactory.rightArrowIcon()}
                    </div>
                  </div>

                  <div className={`claimRegister__data-panel__section${!adjusterInfo.liability ? ' claimRegister__data-panel__section--disabled' : ''}`}
                    onClick={() => navigatePage('forward', CLAIM_ADJUSTER_PARAMS.PERSON_MANAGEMENT)}
                  >
                    <div className="claimRegister__data-panel__section__icon">
                      <span className="claimRegister__data-panel__section__icon__bg" style={{ backgroundImage: `url(${Util.ASSET.CLAIMS_PANEL_PEOPLE})` }}></span>
                    </div>

                    <div className="claimRegister__data-panel__section__title">
                      <Text type="label" block>Gesti&oacute;n de</Text>
                      <Text type="paragraph" bold>Involucrados</Text>
                    </div>

                    <div className="claimRegister__data-panel__section__arrow">
                      {IconFactory.rightArrowIcon()}
                    </div>
                  </div>

                  <div className={`claimRegister__data-panel__section${(!adjusterInfo.liability || !adjusterInfo.isInvolvedsCompleted) ? ' claimRegister__data-panel__section--disabled' : ''}`}
                    onClick={() => navigatePage('forward', CLAIM_ADJUSTER_PARAMS.CLOSURE)}
                  >
                    <div className="claimRegister__data-panel__section__icon">
                      <span className="claimRegister__data-panel__section__icon__bg" style={{ backgroundImage: `url(${Util.ASSET.CLAIMS_PANEL_CLOSURE})` }}></span>
                    </div>

                    <div className="claimRegister__data-panel__section__title">
                      <Text type="label" block>Cierre y</Text>
                      <Text type="paragraph" bold>Conclusiones</Text>
                    </div>

                    <div className="claimRegister__data-panel__section__arrow">
                      {IconFactory.rightArrowIcon()}
                    </div>
                  </div>

                  <div className="claimRegister__data-panel__separator"></div>

                  <div className="claimRegister__data-panel__infoSection claimRegister__data-panel__section">
                    <div className="claimRegister__data-panel__infoSection__icon">
                      <span className="claimRegister__data-panel__infoSection__icon__bg" style={{ backgroundImage: `url(${Util.ASSET.INFO_IMAGE})` }}></span>
                    </div>

                    <div className="claimRegister__data-panel__infoSection__title">
                      <Text type="paragraph">Consulta los datos de la póliza y del siniestro</Text>
                    </div>

                    <div className="claimRegister__data-panel__infoSection__button" onClick={() => navigatePage('forward', CLAIM_ADJUSTER_PARAMS.INFO)}>
                      <Button color="main" fill="outline" shadowless>Ver info</Button>
                    </div>
                  </div>
                </div>

                <div className="claimRegister__button-wrapper">
                  <Button type="button"
                    disabled={!validate.validateClousure() || !validate.validateDeclaration()}
                    onClick={() => checkAdjusterConditions()}
                  >
                    Finalizar
                  </Button>
                </div>

                <Modal show={states.showNoticesModal} position="center">
									<div className="claimAdjuster__noticesModal">
                    <div className="claimAdjuster__noticesModal__warningImage" style={{ backgroundImage: `url(${Util.ASSET.WARNING_IMAGE})`}}></div>
										<Text type="title" align="center">({adjusterInfo?.formNotices?.length}) Faltantes por realizar</Text>
                    <Text type="paragraph" align="center">Aun así tienes la posiblidad de firmar <br/> y cerrar el formulario.</Text>
										
                    <div className="claimAdjuster__noticesModal__advicesWrapper">
                      {
                        adjusterInfo?.formNotices && adjusterInfo.formNotices.map((n, index) => {
                          return (
                            <div className="claimAdjuster__noticesModal__advicesWrapper__item" key={'noticeItem-'+index}>
                              <div className="claimAdjuster__noticesModal__advicesWrapper__item__icon">
                                {IconFactory.warningIcon()}
                              </div>
                              <Text type="paragraph">{n}</Text>
                            </div>
                          )
                        })
                      }
                    </div>

										<div className="claimAdjuster__noticesModal__buttonsWrapper">
											<Button 
												type="button" 
                        fill="outline"
												loading={updating.value}
                        disabled={updating.value}
												onClick={() => {states.setShowNoticesModal(false); navigatePage('forward', CLAIM_ADJUSTER_PARAMS.CLAIM_SIGNATURE)}}
											>
												Continuar
											</Button>

											<Button 
												type="button" 
												disabled={updating.value}
												onClick={() => states.setShowNoticesModal(false)}
											>
												Volver
											</Button>
										</div>
									</div>
								</Modal>
              </div>
            </ClaimContent>
          }

          {
            sectionName === CLAIM_ADJUSTER_PARAMS.DECLARATION &&
              <ClaimContent
                title="Datos generales"
                backButton={
                  <button type="button" className="claimRegister__button-back" onClick={() => navigatePage('backward', CLAIM_ADJUSTER_PARAMS.PANEL)}>
                    {IconFactory.leftArrowIcon()}
                  </button>
                }
              >
                <div className="claimAdjuster__content">
                  <div className="claimAdjuster__content">
                    <div className="claimAdjuster__content__section">
                      <div className="claimAdjuster__content__section__header">
                        {
                          (!form.getFieldState('facts').invalid && form.getValues('insuredSignature')) || 
                          (adjusterInfo.peopleFacts.length > 0) ?
                            <span className="claimAdjuster__content__section__header__marker claimAdjuster__content__section__header__marker--valid"></span>
                            :
                            <span className="claimAdjuster__content__section__header__marker claimAdjuster__content__section__header__marker--invalid"></span>
                        }
                        <Text type="subtitle">Declaraci&oacute;n de NA</Text>
                      </div>

                      <div className="claimAdjuster__content__section__field">
                        <Controller
                          control={form.control}
                          name="facts"
                          rules={
                            {
                              required: true,
                            }
                          }
                          render={({ field: { onChange } }) => (
                            <InputArea 
                              maxLength={5000}
                              rows={8}
                              placeholder="Escribe una narración de los hechos"
                              errors={!!form.formState.errors.facts && form.formState.dirtyFields.facts}
                              defaultValue={form.getValues('facts')}
                              disabled={!adjusterInfo.isFirstForm || adjusterInfo.peopleFacts.length > 0}
                              onChange={(e) => onChange(e.target.value)}
                              name="facts"
                            />
                          )}
                        />

                        <div className="claimAdjuster__content__section__field__privacyText">
                          <Text type="label" align="center">
                            Al firmar estás confirmando que la declaración de hechos es verídica, y que has leído y aceptado el <a target="_blank" href="https://www.crabi.com/legal/aviso-de-privacidad" rel="noreferrer">Aviso de Privacidad</a> de Crabi.
                          </Text>
                        </div>

                        <div className="claimAdjuster__content__section__field__signature">
                          {
                            form.getValues('insuredSignature') ?
                              <div className="claimAdjuster__content__section__field__signature__preview">
                                {
                                  (adjusterInfo.isFirstForm && !adjusterInfo.peopleFacts) &&
                                    <button type="button" className="claimAdjuster__content__section__field__signature__preview__edit-button"
                                      onClick={() => navigatePage('forward', CLAIM_ADJUSTER_PARAMS.DECLARATION_SIGNATURE)}
                                    >
                                      {IconFactory.editIcon()}
                                    </button>
                                }
                                <img alt="insured-signature" src={form.getValues('insuredSignature')} />
                              </div>
                              :
                              <div className="claimAdjuster__content__section__field__signature__button">
                                <Button type="button"
                                  disabled={form.getFieldState('facts').invalid || !adjusterInfo.isFirstForm || adjusterInfo.peopleFacts.length > 0}
                                  onClick={() => navigatePage('forward', CLAIM_ADJUSTER_PARAMS.DECLARATION_SIGNATURE)}
                                >
                                  Firmar declaraci&oacute;n
                                </Button>
                              </div>
                          }
                        </div>
                      </div>
                    </div>

                    <div className="claimAdjuster__content__section">
                      <div className="claimAdjuster__content__section__header">
                        {
                          states.selectedLiability ?
                            <span className="claimAdjuster__content__section__header__marker claimAdjuster__content__section__header__marker--valid"></span>
                            :
                            <span className="claimAdjuster__content__section__header__marker claimAdjuster__content__section__header__marker--invalid"></span>
                        }
                        <Text type="subtitle">Responsabilidad</Text>
                      </div>
                      <div className="claimAdjuster__content__section__field">
                        <Dropdown
                          placeholder="Selecciona una responsabilidad"
                          defaultValue={states.selectedLiability ? { id: states.selectedLiability.id, value: states.selectedLiability.name } : undefined}
                          onChange={(item) => states.selectLiabilityById(item.id as string)}
                          items={list.liabilityList ? list.liabilityList.map(l => ({ id: l.id, value: l.name })) : []}
                        />
                      </div>
                    </div>

                    <div className="claimAdjuster__content__section">
                      <div className="claimAdjuster__content__section__header">
                        {
                          states.selectedCause ?
                            <span className="claimAdjuster__content__section__header__marker claimAdjuster__content__section__header__marker--valid"></span>
                            :
                            <span className="claimAdjuster__content__section__header__marker claimAdjuster__content__section__header__marker--invalid"></span>
                        }
                        <Text type="subtitle">Causa</Text>
                      </div>
                      <div className="claimAdjuster__content__section__field">
                        <Dropdown
                          placeholder="Selecciona una causa"
                          defaultValue={states.selectedCause ? { id: states.selectedCause.id, value: states.selectedCause.name } : undefined}
                          onChange={(item) => states.selectCauseById(item.id as string)}
                          items={list.causesList ? list.causesList.map(c => ({ id: c.id, value: c.name })) : []}
                        />
                      </div>
                    </div>

                    {
                      (adjusterInfo.claimType.isClaimCollision() && adjusterInfo.email) &&
                        <div className="claimAdjuster__content__section">
                          <div className="claimAdjuster__content__section__header">
                            {
                              (states.selectedCircumstance && states.selectedCircumstance.id) ?
                                <span className="claimAdjuster__content__section__header__marker claimAdjuster__content__section__header__marker--valid"></span>
                                :
                                <span className="claimAdjuster__content__section__header__marker claimAdjuster__content__section__header__marker--invalid"></span>
                            }
                            <Text type="subtitle">Circunstancia</Text>
                          </div>
                          <div className="claimAdjuster__content__section__field">
                            <Dropdown
                              placeholder="Selecciona una causa"
                              defaultValue={states.selectedCircumstance ? { id: states.selectedCircumstance.id, value: states.selectedCircumstance.name } : undefined}
                              onChange={(item) => states.selectCircumstanceById(item.id as string)}
                              items={list.circumstancesList ? list.circumstancesList.map(c => ({ id: c.id, value: c.name })) : []}
                            />
                          </div>
                        </div>
                    }

                    <div className="claimAdjuster__content__section">
                      <div className="claimAdjuster__content__section__header">
                        {
                          referencePictures.isValidPictures ?
                            <span className="claimAdjuster__content__section__header__marker claimAdjuster__content__section__header__marker--valid"></span>
                            :
                            <span className="claimAdjuster__content__section__header__marker claimAdjuster__content__section__header__marker--invalid"></span>
                        }
                        <Text type="subtitle">Fotos de crucero</Text>
                      </div>

                      <Text className="claimAdjuster__content__section__vehiclePicturesText" type="paragraph" align="center">Solo fotos del sitio de accidente y señales restrictivas (más adelante se suben las de vehículo, daños y documentos).</Text>
                      
                      <div className="claimAdjuster__content__section__field">
                        {
                          referencePictures.pictures.map(
                            (src, index) => (
                              <div key={`description-image-${index}`}>
                                <ClaimPicture
                                  defaultPicture={src}
                                  onSelected={(picture) => referencePictures.addPicture(picture, index)}
                                  hasDelete={index > 0}
                                  removePicture={() => referencePictures.removePicture(index)}
                                />
                                <Text type="label" align="center" block bold>Foto #{index + 1}</Text>
                              </div>
                            )
                          )
                        }

                        <div className="claimAdjuster__content__section__add-button">
                          <button type="button" onClick={() => referencePictures.newPicture()}>
                            {IconFactory.addIcon()}
                          </button>
                          <Text type="label" bold align="center">A&ntilde;adir otra imagen</Text>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="claimRegister__button-wrapper">
                    <Button type="button"
                      loading={updating.value}
                      disabled={
                        ((!form.formState.isValid || form.getValues('insuredSignature') === undefined) && adjusterInfo.isFirstForm && !adjusterInfo.peopleFacts) || !referencePictures.isValidPictures ||
                        !states.selectedCause || !states.selectedLiability || updating.value
                      }
                      onClick={() => sendCurrentAdjusterInfo(false, referencePictures.pictures, 'references').then(() => navigatePage('backward', CLAIM_ADJUSTER_PARAMS.PANEL))}
                    >
                      Confirmar
                    </Button>
                  </div>
                </div>
              </ClaimContent>
          }

          {
            sectionName === CLAIM_ADJUSTER_PARAMS.DECLARATION_SIGNATURE &&
              <ClaimContent
                title="Firma digital"
                subtitle="Declaración NA"
                backButton={
                  <button type="button" className="claimRegister__button-back" onClick={() => navigatePage('backward', CLAIM_ADJUSTER_PARAMS.DECLARATION)}>
                    {IconFactory.leftArrowIcon()}
                  </button>
                }
              >
                <div className="claimAdjuster__content">
                  <div className="claimAdjuster__content__section">
                    <Controller
                      control={form.control}
                      name="previewInsuredSignature"
                      rules={{
                        required: true
                      }}
                      render={({ field: { onChange } }) => (
                        <ClaimSignature
                          label="Firma del asegurado"
                          onLoad={() => onChange('')}
                          onSign={(signature) => onChange(signature!)}
                          personFullName={adjusterInfo.getNaPersonName()}
                          defaultImage={form.getValues('insuredSignature')}
                        />
                      )}
                    />
                  </div>
                  <div className="claimRegister__button-wrapper">
                    <Button type="button"
                      disabled={!form.formState.isValid && form.getValues('insuredSignature') === undefined}
                      onClick={() => { form.setValue('insuredSignature', form.getValues('previewInsuredSignature') as string); navigatePage('backward', CLAIM_ADJUSTER_PARAMS.DECLARATION) }}
                    >
                      Aceptar
                    </Button>
                  </div>
                </div>
              </ClaimContent>
          }

          {
            sectionName === CLAIM_ADJUSTER_PARAMS.PERSON_MANAGEMENT &&
              <Outlet />
          }

          {
            sectionName === CLAIM_ADJUSTER_PARAMS.CLOSURE &&
              <ClaimContent
                title="Conclusiones"
                backButton={
                  <button type="button" className="claimRegister__button-back" onClick={() => navigatePage('backward', CLAIM_ADJUSTER_PARAMS.PANEL)}>
                    {IconFactory.leftArrowIcon()}
                  </button>
                }
              >
                <div className="claimAdjuster__content">
                  <div className="claimAdjuster__content">

                    <div className="claimAdjuster__content__section">
                      <div className="claimAdjuster__content__section__header">
                        <span className="claimAdjuster__content__section__header__marker claimAdjuster__content__section__header__marker--valid"></span>
                        <Text type="subtitle">Razón de no procedencia</Text>
                        &nbsp;
                        <Text type="paragraph"> (solo si aplica)</Text>
                      </div>

                      <div className="claimAdjuster__content__section__field">
                        <Dropdown
                          placeholder="Selecciona una razón"
                          defaultValue={states.selectedReason ? { id: states.selectedReason.id, value: states.selectedReason.name } : undefined}
                          onChange={(item) => states.selectReasonById(item.id as string)}
                          hasDeleteButton
                          onDelete={deleteReason}
                          items={list.reasonsList ? list.reasonsList.map(l => ({ id: l.id, value: l.name })) : []}
                        />
                      </div>
                    </div>

                    <div className="claimAdjuster__content__section">
                      <div className="claimAdjuster__content__section__header">
                        {
                          !form.getFieldState('closure').invalid ?
                            <span className="claimAdjuster__content__section__header__marker claimAdjuster__content__section__header__marker--valid"></span>
                            :
                            <span className="claimAdjuster__content__section__header__marker claimAdjuster__content__section__header__marker--invalid"></span>
                        }
                        <Text type="subtitle">Conclusiones del cierre</Text>
                      </div>

                      <Text className="claimAdjuster__content__section__conclusionText" type="paragraph" align="center">Si es complementaria, coloca tus comentarios a continuación de las conclusiones originales.</Text>

                      <div className="claimAdjuster__content__section__field">
                        <Controller
                          control={form.control}
                          name="closure"
                          rules={
                            {
                              required: true,
                            }
                          }
                          render={({ field: { onChange } }) => (
                            <InputArea
                              maxLength={5000}
                              rows={8}
                              placeholder="Escribe una narración del cierre"
                              errors={!!form.formState.errors.closure && form.formState.dirtyFields.closure}
                              defaultValue={form.getValues('closure')}
                              onChange={(e) => onChange(e.target.value)}
                              name="closure"
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className="claimAdjuster__content__section">
                      <div className="claimAdjuster__content__section__header">
                        <span className="claimAdjuster__content__section__header__marker claimAdjuster__content__section__header__marker--valid"></span>
                        <Text type="subtitle">Foto de DUA</Text>
                      </div>

                      <Text className="claimAdjuster__content__section__conclusionText" type="paragraph" align="center">Solo en caso de que se haya rellenado DUA físico o tengamos copia del tercero; si no dejar vacío.</Text>

                      <div className="claimAdjuster__content__section__field">
                        <Text type="paragraph" align="center" block bold>Capturar foto de DUA</Text>

                        {
                          duaPictures.pictures.map(
                            (src, index) => (
                              <div key={`description-image-${index}`}>
                                <ClaimPicture
                                  defaultPicture={src}
                                  onSelected={(picture) => duaPictures.addPicture(picture, index)}
                                  hasDelete={index > 0}
                                  removePicture={() => duaPictures.removePicture(index)}
                                />
                                <Text type="label" align="center" block bold>Foto #{index + 1}</Text>
                              </div>
                            )
                          )
                        }

                        <div className="claimAdjuster__content__section__add-button">
                          <button type="button" onClick={() => duaPictures.newPicture()}>
                            {IconFactory.addIcon()}
                          </button>
                          <Text type="label" bold align="center">A&ntilde;adir otra imagen</Text>
                        </div>
                      </div>
                    </div>

                    <div className="claimRegister__button-wrapper">
                      <Button type="button"
                        loading={updating.value}
                        disabled={
                          !form.formState.isValid || updating.value
                        }
                        onClick={() => sendCurrentAdjusterInfo(false, duaPictures.pictures, 'dua').then(() => navigatePage('backward', CLAIM_ADJUSTER_PARAMS.PANEL))}
                      >
                        Completar
                      </Button>
                    </div>
                  </div>
                </div>
              </ClaimContent>
          }

          {
            sectionName === CLAIM_ADJUSTER_PARAMS.CLAIM_SIGNATURE &&
              <ClaimContent
                title="Firma de conformidad"
                subtitle="Confirmación para el cierre del siniestro"
                backButton={
                  <button type="button" className="claimRegister__button-back" onClick={() => navigatePage('backward', CLAIM_ADJUSTER_PARAMS.PANEL)}>
                    {IconFactory.leftArrowIcon()}
                  </button>
                }
              >
                <div className="claimAdjuster__content">
                  <div className="claimAdjuster__content">
                    <Controller
                      control={form.control}
                      name="adjusterSignature"
                      rules={{
                        required: true
                      }}
                      render={({ field: { onChange } }) => (
                        <ClaimSignature
                          defaultImage={form.getValues('adjusterSignature')}
                          personFullName={adjusterInfo.name}
                          onSign={(signature) => {onChange(signature!); syncStates.setAdjusterSignature(signature)}}
                          label="Firma del ajustador"

                        />

                      )}
                    />
                    <Text type="paragraph" align="center">
                      <br /> <b>⚠️ &nbsp; Atención: </b> Una vez cerrado el siniestro no se podr&aacute; modificar.
                    </Text>
                  </div>

                  <div className="claimRegister__button-wrapper">
                    <Button type="button"
                      loading={updating.value || loading.value || amisLoading.value}
                      disabled={!form.formState.isValid || updating.value || loading.value || amisLoading.value}
                      onClick={() => amisServicesHandler()}
                    >
                      Cerrar siniestro
                    </Button>
                  </div>

                  <Modal show={syncStates.showAmisErrorModal} position="center">
                    <div className="claimAdjuster__arrivalModal">
                      {
                        syncStates.amisErrorMode === 'receive' &&
                          <Text type="subtitle" align="center">Ningún tercero tiene servicio de <b>SIPAC u Orden Tradicional.</b> ¿Estás seguro que deseas cerrar el form?</Text>
                      }

                      {
                        syncStates.amisErrorMode === 'emit' &&
                          <Text type="subtitle" align="center">Uno de los terceros <b>NO tiene servicios (SIPAC u Orden tradicional)</b> y no se generará orden ¿Estás seguro que deseas cerrar el form?</Text>
                      }

                      {
                        syncStates.amisErrorMode === 'noPayment' &&
                          <Text type="subtitle" align="center">El tercero <b>NO ha confirmado el pago.</b> ¿Estás seguro que deseas cerrar el form?</Text>
                      }
                      
                      <div className="claimAdjuster__arrivalModal__buttonsWrapper">
                        <Button 
                          fill="outline"
                          disabled={loading.value}
                          onClick={() => syncStates.setShowAmisErrorModal(false)}
                        >
                          Cancelar
                        </Button>

                        <Button 
                          type="button" 
                          loading={loading.value}
                          disabled={loading.value}
                          onClick={() => {
                            if (syncStates.amisErrorMode === 'noPayment')
                              finishEduaFlow(true)
                            else if (syncStates.amisErrorMode === 'emit')
                              amisServicesHandler(true, true)
                            else
                              finishEduaFlow(true)
                          }} 
                        >
                          Sí, continuar
                        </Button>
                      </div>
                    </div>
                  </Modal>
                </div>
              </ClaimContent>
          }

          {
            sectionName === CLAIM_ADJUSTER_PARAMS.COMPLETED &&
            <ClaimContent
              title=""
            >
              <div className="claimAdjuster__content">

                <div className="claimRegister__completion">
                  <div className="claimRegister__completion__logo">
                    <Logo type="full" />
                  </div>

                  <Text type="paragraph" align="center">Muchas gracias, <b>{adjusterInfo.name}</b></Text>
                  <Text type="title" align="center" className="claimRegister__completion__title">
                    A partir de ahora el formulario queda cerrado y no se podrá acceder de nuevo a él.
                  </Text>
                  <Text type="paragraph" align="center">Si necesitas agregar información <br /> adicional por favor contáctanos.</Text>

                  <Text type="paragraph" align="center" bold className="claimRegister__completion__link">Ir a <a href="https://crabi.com" target="_self" >crabi.com</a></Text>

                </div>


              </div>
            </ClaimContent>
          }
        </>
      }

    </div >
  );
};

export default ClaimAdjusterPage;