import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ClaimRegisterSelector } from "../selectors/claim-register.selector";
import { useDispatch, useSelector } from "react-redux";
import { CLAIM_ADJUSTER_PARAMS, CLAIM_ADJUSTER_PERSON_EDIT_PARAMS, CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS, CLAIM_REGISTER_PATH } from "src/routes/paths/claim-register.paths";
import { IClaimPersonForm } from "../interfaces/IClaimPersonForm";
import { useForm } from "react-hook-form";
import { Address } from "src/classes/Address";
import { LoadingFactory } from "src/factory/loading.factory";
import { StatusTypeItem } from "src/classes/claims/StatusTypeItem";
import { Damage } from "src/classes/claims/Damage";
import { Service } from "src/classes/claims/Service";
import { ClaimRegisterFeature } from "../features/claim-register.feature";
import { ClaimRegisterAction } from "../actions/claim-register.action";
import { AlertFactory } from "src/factory/alert.factory";
import { ClaimRegister } from "src/classes/claims/ClaimRegister";
import { Util } from "src/utils/Util";
import { ClaimRegisterPicture } from "src/classes/claims/ClaimRegisterPicture";
import { DebounceFactory } from "src/factory/debounce.factory";
import { Provider } from "src/classes/claims/Provider";
import { ClaimPersonSyncFacade } from "./claim-person-sync.facade";


export namespace ClaimPersonManagementFacade {

	export const GENDERS = [
		{ id: Util.STATUS_CODE.GENDER.MALE, value: 'Masculino' },
		{ id: Util.STATUS_CODE.GENDER.FEMALE, value: 'Femenino' }
	];

	export const PROVIDER_TYPES = [
		{ id: Util.STATUS_CODE.CLAIM_PROVIDERS_TYPES.MULTIBRAND, value: 'Multimarca' },
		{ id: Util.STATUS_CODE.CLAIM_PROVIDERS_TYPES.AGENCY, value: 'Agencia' }
	];

	/**
	 * 
	 */
	export const NAVIGATION_PARAMS = {
		MANAGEMENT: 'personManagement',
		PERSON_TYPE: 'personType',
		PERSON_EDIT: 'personEdit',
		GENERAL_EDIT_SECTION: 'generalEdit',
		VEHICLE_EDIT_SECTION: 'vehicleEdit',
		DAMAGE_EDIT_SECTION: 'damageEdit',
		SERVICE_EDIT_SECTION: 'serviceEdit',
		SIGNATURE: 'signature'
	}

	export const GENERAL_NAVIGATION_PARAMS = {
		DATA: 'data',
		LICENSE_PICTURES: 'licensePictures',
		INE: 'ine',
		DRIVING_CARD: 'drivingCard',
		LICENSE_DATA: 'licenseData',
		INSURANCE: 'insurance',
		EDIT_CLAIM_REGISTER: 'editClaimRegister'
	}

	export const VEHICLE_NAVIGATION_PARAMS = {
		DATA: 'data',
		PICTURES: 'pictures',
		VIN: 'vin',
		EDIT_CLAIM_REGISTER: 'editClaimRegister'
	}

	export const DAMAGE_NAVIGATION_PARAMS = {
		LIST: 'list',
		DATA: 'data',
		SIGNATURE: 'signature',
		EDIT_CLAIM_REGISTER: 'editClaimRegister'
	}

	export const SERVICE_NAVIGATION_PARAMS = {
		LIST: 'list',
		TYPE: 'type',
		DATA: 'data',
		EDIT_CLAIM_REGISTER: 'editClaimRegister'
	}

	export enum PERSON_MANAGEMENT_SECTIONS_IDS {
		personal,
		vehicle,
		damages,
		services
	}

	export enum NAVIGATE_DIRECTIONS {
		backward,
		forward
	}

	export const PERSON_MANAGEMENT_SECTIONS = [
		{ uid: PERSON_MANAGEMENT_SECTIONS_IDS.personal, title: 'Datos', subtitle: 'Personales', asset: Util.ASSET.CLAIMS_PANEL_LICENSE },
		{ uid: PERSON_MANAGEMENT_SECTIONS_IDS.vehicle, title: 'Información del', subtitle: 'Vehículo', asset: Util.ASSET.CLAIMS_PANEL_VEHICLE_ID },
		{ uid: PERSON_MANAGEMENT_SECTIONS_IDS.damages, title: 'Lista de', subtitle: 'Daños', asset: Util.ASSET.CLAIMS_PANEL_VEHICLE_DAMAGE },
		{ uid: PERSON_MANAGEMENT_SECTIONS_IDS.services, title: 'Lista de', subtitle: 'Servicios', asset: Util.ASSET.CLAIMS_SERVICE_IMAGE }
	]

	/**
	 * 
	 */
	export const PERSONAL_PAGES_ORDER = [
		GENERAL_NAVIGATION_PARAMS.DATA,
		GENERAL_NAVIGATION_PARAMS.LICENSE_PICTURES,
		GENERAL_NAVIGATION_PARAMS.INE,
		GENERAL_NAVIGATION_PARAMS.DRIVING_CARD,
		GENERAL_NAVIGATION_PARAMS.LICENSE_DATA,
		GENERAL_NAVIGATION_PARAMS.INSURANCE,
		GENERAL_NAVIGATION_PARAMS.EDIT_CLAIM_REGISTER
	];

  /**
   * 
   */
  export const useClaimPersonManagement = () => {
    const registerInfo = useSelector(ClaimRegisterSelector.registerInfo);
		const adjusterInfo = useSelector(ClaimRegisterSelector.adjusterInfo);
    const { sectionName, formId, key } = useParams<string>();
		const { search, pathname } = useLocation();
		const navigate = useNavigate();
		const queryParams = new URLSearchParams(search);

		/**
		 * 
		 * @param uid 
		 */
		const sectionNavigation = (uid: number) => {
			let _navigationItem = '';

			if (uid === PERSON_MANAGEMENT_SECTIONS_IDS.personal)
				_navigationItem = NAVIGATION_PARAMS.GENERAL_EDIT_SECTION;
			else if (uid === PERSON_MANAGEMENT_SECTIONS_IDS.vehicle)
				_navigationItem = NAVIGATION_PARAMS.VEHICLE_EDIT_SECTION;
			else if (uid === PERSON_MANAGEMENT_SECTIONS_IDS.damages)
				_navigationItem = NAVIGATION_PARAMS.DAMAGE_EDIT_SECTION;
			else if (uid === PERSON_MANAGEMENT_SECTIONS_IDS.services)
				_navigationItem = NAVIGATION_PARAMS.SERVICE_EDIT_SECTION;

			navigateTo(_navigationItem);
		};

		/**
		 * 
		 * @param navParam 
		 * @param replace 
		 */
		const navigateTo = (navParam: string, replace?: boolean, index?: number, deleteParams?: boolean) => {
			const urlFragment = `${CLAIM_REGISTER_PATH.ADJUSTER}/${sectionName}/${formId}/${key}/person`;
			const indexParam = index !== undefined && !deleteParams && !search.includes(`personIndex=${index}`) ? `?personIndex=${index}` : ``;
			const urls = {
				'personManagement': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.PERSONS_LIST}`,
				'personType': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.PERSON_TYPE}`,
				'personEdit': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.GENERAL}`,
				'generalEdit': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.EDIT}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.GENERAL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DATA}`,
				'vehicleEdit': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.EDIT}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.VEHICLE}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DATA}`,
				'damageEdit': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.EDIT}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.DAMAGE}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LIST}`,
				'serviceEdit': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.EDIT}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.SERVICE}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LIST}`,
				'signature': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.SIGNATURE}`
			}

			navigate(`${urls[navParam as keyof { }]}${indexParam}${index === undefined && !deleteParams ? search : ''}`, { replace: replace });
		}

		/**
		 * 
		 * @param currentPage 
		 */
		const personalDataForwardNavigation = (currentPage: string, personIndex?: number) => {
			const PERSONAL_DATA_URL = `${CLAIM_REGISTER_PATH.ADJUSTER}/${sectionName}/${formId}/${key}/person/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.EDIT}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.GENERAL}`;
			const GENERAL_MANAGEMENT_URL = `${CLAIM_REGISTER_PATH.ADJUSTER}/${sectionName}/${formId}/${key}/person/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.GENERAL}`;
			const isInsuredOrDriver = registerInfo && (registerInfo.personType.isNA() || registerInfo?.personType.isThirdPartyDriver());
			const isNotWindscreenOrRobberyClaim = adjusterInfo && !adjusterInfo.claimType.isClaimWindscreen() && !adjusterInfo.claimType.isClaimRobbery();
			const isNotThirdPartyDriver = registerInfo && !registerInfo.personType.isThirdPartyDriver();
			let destinationPage = '';

			switch (currentPage) {
				case GENERAL_NAVIGATION_PARAMS.DATA:
					const hasLicense = registerInfo && registerInfo.licensePictures.some((lic) => lic !== undefined);
					const hasINE = registerInfo && registerInfo.inePictures.some((lic) => lic !== undefined);

					if (hasINE && !hasLicense)
						destinationPage = `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.INE}`;
					else if ((isInsuredOrDriver && isNotWindscreenOrRobberyClaim) || hasLicense) 
						destinationPage = `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LICENSE_PICTURES}`;
					else
						destinationPage = `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.INE}`;
					break;

				case GENERAL_NAVIGATION_PARAMS.LICENSE_PICTURES:
					destinationPage = `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DRIVING_CARD}`;
					break;

				case GENERAL_NAVIGATION_PARAMS.INE:
					const isCyclistOrPropertyOwner = registerInfo && (registerInfo.personType.isCyclistPersonType() || registerInfo.personType.isPropertyOwnerPersonType());
					const isNotRobberyClaim = adjusterInfo && !adjusterInfo.claimType.isClaimRobbery();
					
					if (isCyclistOrPropertyOwner)
						destinationPage = `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.INSURANCE_DATA}`;
					else if (isInsuredOrDriver && isNotRobberyClaim)
						destinationPage = `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DRIVING_CARD}`;
					else
						destinationPage = GENERAL_MANAGEMENT_URL;
					break;

				case GENERAL_NAVIGATION_PARAMS.DRIVING_CARD:
					if (isNotWindscreenOrRobberyClaim)
						destinationPage = `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LICENSE_DATA}`;
					else if (isNotThirdPartyDriver)
						destinationPage = `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.INSURANCE_DATA}`;
					else 
						destinationPage = GENERAL_MANAGEMENT_URL;
					break;

				case GENERAL_NAVIGATION_PARAMS.LICENSE_DATA:
					if (isNotThirdPartyDriver)
						destinationPage = GENERAL_MANAGEMENT_URL;
					else 
						destinationPage = `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.INSURANCE_DATA}`;
					break;

				case GENERAL_NAVIGATION_PARAMS.INSURANCE:
					destinationPage = GENERAL_MANAGEMENT_URL;
					break;

				default:
					break;
			}

			if (personIndex !== undefined)
				queryParams.append('personIndex', `${personIndex}`)

			navigate(`${destinationPage}?${queryParams.toString()}`);
		};

		/**
		 * 
		 * @param currentPage 
		 */
		const personalDataBackwardNavigation = (currentPage: string): void => {
			const PERSONAL_DATA_URL = `${CLAIM_REGISTER_PATH.ADJUSTER}/${sectionName}/${formId}/${key}/person/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.EDIT}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.GENERAL}`;
			const GENERAL_MANAGEMENT_URL = `${CLAIM_REGISTER_PATH.ADJUSTER}/${sectionName}/${formId}/${key}/person/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.GENERAL}`;
			const isInsuredOrDriver = registerInfo && (registerInfo.personType.isNA() || registerInfo?.personType.isThirdPartyDriver());
			const isNotWindscreenOrRobberyClaim = adjusterInfo && !adjusterInfo.claimType.isClaimWindscreen() && !adjusterInfo.claimType.isClaimRobbery();
			const hasLicensePictures = registerInfo && registerInfo.licensePictures.length > 0;

			
			let destinationPage = '';

			switch (currentPage) {
				case GENERAL_NAVIGATION_PARAMS.DATA:
					destinationPage = GENERAL_MANAGEMENT_URL;
					break;
				
				case GENERAL_NAVIGATION_PARAMS.LICENSE_PICTURES:
					destinationPage = `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DATA}`;
					break;

				case GENERAL_NAVIGATION_PARAMS.INE:
					if (isInsuredOrDriver && isNotWindscreenOrRobberyClaim && hasLicensePictures)
						destinationPage = `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LICENSE_PICTURES}`;
					else
						destinationPage = `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DATA}`
					break;

				case GENERAL_NAVIGATION_PARAMS.DRIVING_CARD:
					const hasInePictures = registerInfo && !!registerInfo.inePictures && registerInfo?.inePictures?.length > 0;
					if (hasInePictures)
						destinationPage = `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.INE}`;
					else 
						destinationPage = `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LICENSE_PICTURES}`;
					break;

				case GENERAL_NAVIGATION_PARAMS.LICENSE_DATA:
					destinationPage = `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DRIVING_CARD}`;
					break;

				case GENERAL_NAVIGATION_PARAMS.INSURANCE:
					const isCyclistOrPropertyOwner = registerInfo && (registerInfo.personType.isCyclistPersonType() || registerInfo.personType.isPropertyOwnerPersonType());
					if (isCyclistOrPropertyOwner) 
						destinationPage = `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.INE}`;
					else if (isNotWindscreenOrRobberyClaim)
						destinationPage = `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LICENSE_DATA}`;
					else
						destinationPage = `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DRIVING_CARD}`;
					break;

				default:
					break;
			}

			navigate(`${destinationPage}?${queryParams.toString()}`, { replace: true });
		}

		/**
		 * 
		 */
		const directNavigation = (destination: string) => {
			const PERSONAL_DATA_URL = `${CLAIM_REGISTER_PATH.ADJUSTER}/${sectionName}/${formId}/${key}/person/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.EDIT}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.GENERAL}`;
			const urls = {
				'data': `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DATA}`,
				'licensePictures': `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LICENSE_PICTURES}`,
				'ine': `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.INE}`,
				'drivingCard': `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DRIVING_CARD}`,
				'licenseData': `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LICENSE_DATA}`,
				'insurance': `${PERSONAL_DATA_URL}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.INSURANCE_DATA}`
			}

			navigate(`${urls[destination as keyof { }]}?${queryParams.toString()}`);
		}

		/**
		 * 
		 * @param navParam 
		 * @param replace 
		 */
		const generalNavigation = (navParam: string, replace?: boolean, hasIne?: boolean, index?: number) => {
			const urlFragment = `${CLAIM_REGISTER_PATH.ADJUSTER}/${sectionName}/${formId}/${key}/person/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.EDIT}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.GENERAL}`;

			if (queryParams.has('hasIne')) {
				if (pathname.includes(CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DRIVING_CARD) && navParam === GENERAL_NAVIGATION_PARAMS.LICENSE_PICTURES)
					navParam = GENERAL_NAVIGATION_PARAMS.INE;
				else if (pathname.includes(CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LICENSE_PICTURES) && navParam === GENERAL_NAVIGATION_PARAMS.DRIVING_CARD)
					navParam = GENERAL_NAVIGATION_PARAMS.INE;
			}

			if (hasIne !== undefined) {
				if (hasIne && !queryParams.has('hasIne'))
					queryParams.append('hasIne', 'true');
				else {
					queryParams.delete('hasIne');
				}
			}

			if (index !== undefined) {
				queryParams.append('personIndex', `${index}`)
			}

			const urls = {
				'data': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DATA}`,
				'licensePictures': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LICENSE_PICTURES}`,
				'ine': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.INE}`,
				'drivingCard': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DRIVING_CARD}`,
				'licenseData': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LICENSE_DATA}`,
				'insurance': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.INSURANCE_DATA}`,
				'editClaimRegister': `${CLAIM_REGISTER_PATH.ADJUSTER}/${sectionName}/${formId}/${key}/person/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.GENERAL}`
			}

			navigate(`${urls[navParam as keyof { }]}?${queryParams.toString()}`, { replace: replace });
		}

		/**
		 * 
		 * @param navParam 
		 * @param replace 
		 */
		const vehicleNavigation = (navParam: string, replace?: boolean) => {
			const urlFragment = `${CLAIM_REGISTER_PATH.ADJUSTER}/${sectionName}/${formId}/${key}/person/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.EDIT}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.VEHICLE}`;
			const urls = {
				'data': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DATA}`,
				'pictures': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.VEHICLE_PICTURES}`,
				'vin': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.VEHICLE_VIN}`,
				'editClaimRegister': `${CLAIM_REGISTER_PATH.ADJUSTER}/${sectionName}/${formId}/${key}/person/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.GENERAL}`
			}

			navigate(`${urls[navParam as keyof { }]}${search}`, { replace: replace });
		}

		/**
		 * 
		 * @param navParam 
		 * @param replace 
		 */
		const damageNavigation = (navParam: string, replace?: boolean) => {
			const urlFragment = `${CLAIM_REGISTER_PATH.ADJUSTER}/${sectionName}/${formId}/${key}/person/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.EDIT}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.DAMAGE}`;
			const urls = {
				'list': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LIST}`,
				'data': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DATA}`,
				'editClaimRegister': `${CLAIM_REGISTER_PATH.ADJUSTER}/${sectionName}/${formId}/${key}/person/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.GENERAL}`
			}

			navigate(`${urls[navParam as keyof { }]}${search}`, { replace: replace });
		}

		/**
		 * 
		 * @param navParam 
		 * @param replace 
		 */
		const serviceNavigation = (navParam: string, replace?: boolean, isEditing?: boolean) => {
			const urlFragment = `${CLAIM_REGISTER_PATH.ADJUSTER}/${sectionName}/${formId}/${key}/person/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.EDIT}/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.SERVICE}`;

			if (isEditing !== undefined) {
				if (isEditing === true && !queryParams.has('isEditing'))
					queryParams.append('isEditing', 'true');
				else {
					queryParams.delete('isEditing');
					navParam = SERVICE_NAVIGATION_PARAMS.LIST;
				}
			}

			const urls = {
				'list': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LIST}`,
				'type': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.SERVICE_TYPE}`,
				'data': `${urlFragment}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DATA}`,
				'editClaimRegister': `${CLAIM_REGISTER_PATH.ADJUSTER}/${sectionName}/${formId}/${key}/person/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.GENERAL}`
			}

			navigate(`${urls[navParam as keyof { }]}?${queryParams.toString()}`, { replace: replace });
		}

		/**
		 * 
		 */
		const panelNavigation = (): void => {
			navigate(`${CLAIM_REGISTER_PATH.ADJUSTER}/${CLAIM_ADJUSTER_PARAMS.PANEL}/${formId}/${key}`, { replace: true });
		}

		/**
		 * 
		 */
		const syncNavigation = (): void => {
			navigate(`${CLAIM_REGISTER_PATH.ADJUSTER}/${sectionName}/${formId}/${key}/person/sync`, { replace: true });
		}

		/**
		 * 
		 */
		useEffect(() => {
			const routeCondition = !pathname.includes(CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.PERSONS_LIST) && !pathname.includes(CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.PERSON_TYPE) && !pathname.includes(CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.SIGNATURE);
			const hasPersonIndex = queryParams.has('personIndex');

			if (routeCondition && !hasPersonIndex && !registerInfo) {
				navigateTo(NAVIGATION_PARAMS.MANAGEMENT);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [pathname, queryParams, registerInfo])

    return {
			queryParams,
			sectionNavigation,
			navigateTo,
			generalNavigation,
			personalDataForwardNavigation,
			personalDataBackwardNavigation,
			directNavigation,
			vehicleNavigation,
			damageNavigation,
			syncNavigation,
			serviceNavigation,
			panelNavigation
		}
  }

	/**
	 * 
	 * @returns 
	 */
	export const useClaimPersonManagementForm = () => {
		const adjusterInfo = useSelector(ClaimRegisterSelector.adjusterInfo);
    const registerInfo = useSelector(ClaimRegisterSelector.registerInfo);
    const countryStates = useSelector(ClaimRegisterSelector.statesList);
    const licenceTypes = useSelector(ClaimRegisterSelector.licenceTypes);
		const vehicleTypes = useSelector(ClaimRegisterSelector.vehicleTypes);
		const personTypes = useSelector(ClaimRegisterSelector.personTypes);
    const colors = useSelector(ClaimRegisterSelector.vehicleColors);
		const bikeDamagesList = useSelector(ClaimRegisterSelector.bikeDamagesList);
		const vehicleDamagesList = useSelector(ClaimRegisterSelector.vehicleDamagesList);
    const [showUserAddress, setShowUserAddress] = useState<boolean>(false);
    const [showInsuranceData, setShowInsuranceData] = useState<boolean>(true);
		const [showInvolvedCreationType, setShowInvolvedCreationType] = useState<boolean>(false);
    const [currentAddress, setCurrentAddress] = useState<Address>();
		const [currentDamage, setCurrentDamage] = useState<Damage>();
    const [selectedDamages, setSelectedDamages] = useState<Damage[]>([]);
		const [damageToEdit, setDamageToEdit] = useState<Damage>();
    const [damageIndexToEdit, setDamageIndexToEdit] = useState<number>();
		const [currentService, setCurrentService] = useState<Service>();
    const [selectedLicenseType, setSelectedLicenseType] = useState<StatusTypeItem>();
		const [selectedProviderState, setSelectedProviderState] = useState<string>();
    const [selectedColor, setSelectedColor] = useState<StatusTypeItem>();
    const [selectedVehicleType, setSelectedVehicleType] = useState<StatusTypeItem>();
		const [selectedProvider, setSelectedProvider] = useState<Provider>();
		const [selectedProviderType, setSelectedProviderType] = useState<number>();
		const [selectedServiceType, setSelectedServiceType] = useState<StatusTypeItem>();
		const [selectedInsuranceCarrier, setSelectedInsuranceCarrier] = useState<Provider>();
		const [selectedCategory, setSelectedCategory] = useState<StatusTypeItem>();
		const [selectedCoverage, setSelectedCoverage] = useState<StatusTypeItem>();
		const [serviceIndexToEdit, setServiceIndexToEdit] = useState<number>();
		const [serviceToEdit, setServiceToEdit] = useState<Service>();
		const [deleteItemIndex, setDeleteItemIndex] = useState<number>(-1);
		const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
		const [showPolicyDeletionModal, setShowPolicyDeletionModal] = useState<boolean>(false);
		const [shouldDeletePolicyData, setShouldDeletePolicyData] = useState<boolean>(false);
		const [personType, setPersonType] = useState<string>();
		const [insuranceCarriersList, setInsuranceCarriersList] = useState<Provider[]>();
		const [providersList, setProvidersList] = useState<Provider[]>();
		const [isSubProvider, setIsSubProvider] = useState<boolean>(false);
		const [serviceTypesList, setServiceTypesList] = useState<StatusTypeItem[]>();
		const [categoriesList, setCategoriesList] = useState<StatusTypeItem[]>();
		const [coveragesList, setCoveragesList] = useState<StatusTypeItem[]>();
		const [isPostalCodeValid, setIsPostalCodeValid] = useState<boolean>(false);
		const contentRef = useRef<HTMLDivElement>(null);
		const [panelSections, setPanelSections] = useState<{ uid: number, title: string, subtitle: string, asset: string }[]>();
		const { formType, sectionName, formId, key, pageName, editPage } = useParams();
		const { pathname } = useLocation();
		const { navigateTo } = useClaimPersonManagement();
		const { synchronize } = ClaimPersonSyncFacade.useClaimPersonSync();
    const isUpdatingForm = LoadingFactory.useLoader();
		const loadingEDUASync = LoadingFactory.useLoader();
    const loadingPostalCodeInfo = LoadingFactory.useLoader();
    const form = useForm<IClaimPersonForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
					savedAddress: true,
          privacy: false,
        }
      }
    );
		const debouncePostalCode = DebounceFactory.useDebounce<string>(form.getValues('postalCode'), 1000);
		const updating = LoadingFactory.useLoader();
		const navigate = useNavigate();
		const { search } = useLocation();
		const searchParams = useMemo(() => new URLSearchParams(search), [search]);

		const dispatch = useDispatch();

		/**
		 * 
		 * @param section 
		 * @returns 
		 */
		const checkSectionAvailability = (section: number): boolean => {
			const isVehicleSectionDisabled = registerInfo && registerInfo.person.name !== undefined && !registerInfo.person.name;
			const isDamageSectionDisabled = registerInfo && (!registerInfo.vehicleType || registerInfo.vehicleType.id.length === 0);
			const isServiceSectionDisabled = registerInfo?.person?.name !== undefined && !registerInfo.person.name;

			if (section === PERSON_MANAGEMENT_SECTIONS_IDS.vehicle)
				return !isVehicleSectionDisabled;
			if (section === PERSON_MANAGEMENT_SECTIONS_IDS.damages)
				return !isDamageSectionDisabled;
			else if (section === PERSON_MANAGEMENT_SECTIONS_IDS.services)
				return !isServiceSectionDisabled;
			else 
				return true;
		}

		/**
     * 
     * @param id 
     */
		const selectLicenceTypeById = (id: string): void => {
			if (licenceTypes) {
				const types = licenceTypes.find(l => l.id === id);
				if (types) {
					setSelectedLicenseType(types);
				}
			}
		}

		/**
     * 
     * @param id 
     */
		const selectVehicleTypeById = (id: string): void => {
			if (vehicleTypes) {
				const type = vehicleTypes.find(l => l.id === id);
				if (type) {
					setSelectedVehicleType(type);
				}
			}
		}

		/**
     * 
     * @param id 
     */
		const selectColorById = (id: string): void => {
			if (colors) {
				const color = colors.find(l => l.id === id);
				if (color) {
					setSelectedColor(color);
				}
			}
		}

		/**
		 * 
		 * @param id 
		 */
		const selectServiceTypeById = (id: string): void => {
			if (serviceTypesList) {
				const serviceType = serviceTypesList.find(c => c.id === id);
				if (serviceType !== undefined) {
					setSelectedServiceType(serviceType);
				}
			}
		}

		/**
		 * 
		 * @param id 
		 */
		const selectInsuranceCarrierById = (id: string): void => {
			if (insuranceCarriersList) {
				const insuranceCarrier = insuranceCarriersList.find(i => i.id === id);
				if (insuranceCarrier)
					setSelectedInsuranceCarrier(insuranceCarrier);
			}
		}

		/**
		 * 
		 * @param id 
		 */
		const selectProviderStateById = (name: string): void => {
			if (countryStates) {
				const selectedState = countryStates.find(i => i.state === name);
				if (selectedState) {
					setSelectedProviderState(selectedState.state)
					setSelectedProvider(undefined);
				}
			}
		}

		/**
		 * 
		 * @param id 
		 */
		const selectCategoryById = (id: string): void => {
			if (categoriesList) {
				const category = categoriesList.find(c => c.id === id);
				if (category) {
					setSelectedCategory(category);
				}					
			}
		}

		/**
		 * 
		 * @param id 
		 */
		const selectProviderById = (id: string): void => {
			if (providersList) {
				const provider = providersList.find(c => c.id === id);
				if (provider) {
					setSelectedProvider(provider);
				}
			}
		}

		/**
		 * 
		 * @param id 
		 */
		const selectProviderTypeById = (id: number): void => {
			const providerType = PROVIDER_TYPES.find(t => t.id === id);
			setSelectedProviderType(providerType?.id);
			setSelectedProvider(undefined);
		}

		/**
		 * 
		 * @param id 
		 */
		const selectCoverageById = (id: string): void => {
			if (coveragesList) {
				const coverage = coveragesList.find(c => c.id === id);
				if (coverage) {
					setSelectedCoverage(coverage);
				}					
			}
		}

		/**
		 * 
		 * @param newAddress 
		 */
		const onSelectAddress = (newAddress?: Address): void => {
      if (newAddress) {
        setCurrentAddress(newAddress);
				loadingPostalCodeInfo.set(true);
        form.setValue('postalCode', newAddress.postalCode, { shouldValidate: true });
        form.trigger('postalCode');
      }
    }

		/**
		 * 
		 */
		const hasOrderMessage = useCallback(() => {
			if (!selectedServiceType)
				return false;

			return (
				selectedServiceType.isRepairServiceType() 
				|| selectedServiceType.isMedicalServiceType() 
				|| selectedServiceType.isPaymentServiceType() 
				|| selectedServiceType.isSipacServiceType() 
				|| selectedServiceType.isTraditionalOrderServiceType()
			);
		}, [selectedServiceType]);

		/**
		 * 
		 */
		const onDeletePolicyDataConfirm = () => {
			const hasAnyPolicyData = form.getValues('policyNumber') || form.getValues('insuranceCompany') || form.getValues('itemNumber')
				|| form.getValues('claimNumber') || form.getValues('duaNumber') || form.getValues('adjuster');

			if (hasAnyPolicyData)
				setShowPolicyDeletionModal(true);
			else {
				setShowInsuranceData(false);
				form.setValue('hasInsurance', false);
			}
		}

		/**
		 * 
		 */
		const deletePolicyData = () => {
			setShowInsuranceData(false);
			setShowPolicyDeletionModal(false);
			setSelectedInsuranceCarrier(undefined);
			form.setValue('hasInsurance', false);
			form.setValue('policyNumber', '');
			form.setValue('insuranceCompany', '');
			form.setValue('itemNumber', '');
			form.setValue('claimNumber', '');
			form.setValue('duaNumber', '');
			form.setValue('adjuster', '');
		}

		/**
		 * 
		 * @param completed 
		 * @param pictures 
		 * @param picturesType 
		 * @returns 
		 */
		const sendCurrentAdjusterInfo = (completed?: boolean, pictures?: string[], picturesType?: TClaimPictureName): Promise<number | void> => {
      return new Promise<number | void>(
        async (resolve, reject) => {
          try {
						let index: number | undefined;

						if (!adjusterInfo || !registerInfo || !key || !formId)
							return;

            isUpdatingForm.set(true);

						if (pathname.includes(CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.GENERAL)) {
							registerInfo.setPersonValues(form.getValues('name'), form.getValues('lastName'), form.getValues('secondLastName'), form.getValues('email'));
							registerInfo.setProfileValues(form.getValues('countryCode'), form.getValues('phone'), form.getValues('birthdate'), form.getValues('gender'));

							if (isPostalCodeValid)
								registerInfo.setAddressValues(form.getValues('postalCode'), currentAddress?.name, form.getValues('municipality'), form.getValues('state'));
							
							if (form.getValues('licenseNumber') || selectedLicenseType || form.getValues('licenseState') || form.getValues('licenseStartDate') || form.getValues('licenseEndDate'))
								registerInfo.setLicenceValues(form.getValues('licenseNumber'), selectedLicenseType, form.getValues('licenseState'), form.getValues('licenseStartDate'), form.getValues('licenseEndDate'));

							if (shouldDeletePolicyData)
								registerInfo.deletePolicyValues();
							else if (selectedInsuranceCarrier)
								registerInfo.setPolicyValues(selectedInsuranceCarrier.id, selectedInsuranceCarrier.name, form.getValues('policyNumber'), form.getValues('itemNumber'), form.getValues('claimNumber'), form.getValues('duaNumber'), form.getValues('adjuster'))
						} else if (pathname.includes(CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.VEHICLE)) {
							registerInfo.setVehicleValues(form.getValues('vehicleBrand'), form.getValues('vehicleModel'), form.getValues('vehicleYear'), form.getValues('vin'), selectedColor, form.getValues('plates'), selectedVehicleType, registerInfo.vehicle?.comercialValue);
						} else if (pathname.includes(CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.DAMAGE)) {
							registerInfo.setDamages(selectedDamages);
						} else if (pathname.includes(CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.SERVICE)) {
							let servicePictures: ClaimRegisterPicture[] = [];
							if (pictures) {
								
								const uploadedPictures = await ClaimRegisterFeature.claimServicePicturesFeature(registerInfo, pictures);
								if (uploadedPictures)
									servicePictures = uploadedPictures;
							}

							if (selectedServiceType) {
								registerInfo.setServiceValues(
									selectedServiceType,
									selectedCategory,
									selectedProvider,
									isSubProvider,
									selectedCoverage,
									form.getValues('serviceAmount'),
									form.getValues('serviceSheetNumber'),
									form.getValues('hasDeductible'),
									form.getValues('operatorName'),
									form.getValues('operatorCountryCode'),
									form.getValues('operatorPhone'),
									form.getValues('serviceDescription'),
									servicePictures,
									serviceIndexToEdit
								);
							}

							pictures = [];
						}

						if (form.getValues('signature'))
							registerInfo.signature = form.getValues('signature');

						const indexParam = searchParams.get('personIndex');
						const personIndex = !!indexParam ? parseInt(indexParam) : undefined;
						adjusterInfo.updateClaimPerson(registerInfo, personIndex);
						const newInfo = await ClaimRegisterFeature.claimAdjusterInfoUpdateFeature(key, adjusterInfo, formId, completed || false, pictures, picturesType, personIndex);

						if (!indexParam) {
							index = adjusterInfo.claimPeople.length - 1;
							setServiceEdition(undefined);
						}

						if (newInfo) {
						  dispatch(ClaimRegisterAction.setNewClaimAdjusterInfo(newInfo));
						}

						updating.set(false);

						if (index !== undefined)
							resolve(index);
						else
							resolve();

          } catch (e) {
            AlertFactory.errorAlert((e as Error).message);
						updating.set(false);
            reject();
          } finally {
            isUpdatingForm.set(false);
          }
        }
      )
    }



		/**
     * 
     */
    const setCurrentRegisterInfo = useCallback(
      (info: ClaimRegister) => {
        form.setValue('name', info.person?.name!);
        form.setValue('lastName', info.person?.lastName!);
        form.setValue('secondLastName', info.person?.secondLastName!);
        form.setValue('birthdate', info.person?.profile?.birthdate?.toDateString()!);
        form.setValue('email', info.person?.email!);
        form.setValue('phone', info.person?.profile?.phone?.number!);
        form.setValue('postalCode', info.person?.profile?.address?.postalCode!);
        form.setValue('municipality', info.person?.profile?.address?.municipality!);
        form.setValue('state', info.person?.profile?.address?.state!);
        form.setValue('licenseState', info.license?.stateName!);
        form.setValue('licenseNumber', info.license?.number!);
        form.setValue('licenseStartDate', info.license?.startDate?.toDateString()!);
        form.setValue('licenseEndDate', info.license?.endDate?.toDateString()!);
        form.setValue('vin', info.vehicle?.vin!);

				if (info.thirdInsuranceClaimNumber)
					form.setValue('claimNumber', info.thirdInsuranceClaimNumber);

				if (info.thirdInsuranceDuaNumber)
					form.setValue('duaNumber', info.thirdInsuranceDuaNumber);

				if (info.thirdInsuranceAdjusterName)
					form.setValue('adjuster', info.thirdInsuranceAdjusterName);

				if (info.issueNumber)
					form.setValue('itemNumber', info.issueNumber);

				if (info.policyNumber)
					form.setValue('policyNumber', info.policyNumber);

				form.setValue('vehicleBrand', info.vehicle?.brand?.name!);
				form.setValue('vehicleModel', info.vehicle?.model?.name!);
				form.setValue('vehicleYear', info.vehicle?.year?.value.toString()!);
				form.setValue('plates', info.vehicle?.plate!);

				if (info.signature)
					form.setValue('signature', info.signature);

				if (info.person?.profile?.gender)
					form.setValue('gender', info.person?.profile?.gender);

        form.trigger();

        if (info.person?.profile?.address) {
          setCurrentAddress(info.person?.profile?.address);
        }

        if (info.license?.type) {
          setSelectedLicenseType(info.license.type);
        }

        if (info.vehicleType) {
          setSelectedVehicleType(info.vehicleType);
        }

        if (info.vehicleColor) {
          setSelectedColor(info.vehicleColor);
        }

				if (info.insuranceCompany && info.insuranceCompany.id) {
					selectInsuranceCarrierById(info.insuranceCompany.id)
				}

        if (info.damages.length > 0) {
          let damagesList: Damage[] = [];
          for (const dmg of info.damages) {
            const newStateDmg: Damage = new Damage(dmg.type);
            newStateDmg.id = dmg.id;
            for (const picture of dmg.pictures) {
              let statePicture: ClaimRegisterPicture = new ClaimRegisterPicture(picture.id, picture.url, picture.name);
              if (picture.type) {
                statePicture.type = new StatusTypeItem(picture.type.id, picture.type?.name);
              }
              newStateDmg.pictures.push(statePicture);
            }
            damagesList.push(newStateDmg);
          }
          setSelectedDamages(damagesList);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps 
      []
    );

		/**
		 * 
		 */
		const completeInvolvedsSection = (): void => {
			if (adjusterInfo)
				adjusterInfo.isInvolvedsCompleted = true;

			navigate(`${CLAIM_REGISTER_PATH.ADJUSTER}/${CLAIM_ADJUSTER_PARAMS.PANEL}/${formId}/${key}`, { replace: true });
		}

		/**
		 * 
		 * @param index 
		 */
		const deleteClaimRegister = useCallback(async() => {
			if (adjusterInfo && deleteItemIndex > -1 && key && formId) {
				adjusterInfo.deleteClaimPerson(deleteItemIndex);
				const newInfo = await ClaimRegisterFeature.claimAdjusterInfoUpdateFeature(key, adjusterInfo, formId, false, undefined, undefined);
				
				if (newInfo)
					dispatch(ClaimRegisterAction.setNewClaimAdjusterInfo(newInfo));

				setDeleteItemIndex(-1);
				setShowDeleteModal(false);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [adjusterInfo, deleteItemIndex, dispatch]);

		/**
     * 
     */
    const getStatesByPostalCode = useCallback(
      async () => {
        try {
          if (debouncePostalCode !== '') {
            loadingPostalCodeInfo.set(true);
            const address = await ClaimRegisterFeature.countryStateByPostalCodeFeature(debouncePostalCode);
            if (address) {
              form.setValue('municipality', address.municipality);
              form.setValue('state', address.state);
							setCurrentAddress((_address) => {
								if (_address) {
									_address.municipality = address.municipality;
									_address.state = address.state;
								}
								return _address;
							})
              form.trigger();
							setIsPostalCodeValid(true);
            } else {
							setIsPostalCodeValid(false);
							form.setValue('municipality', '');
              form.setValue('state', '');
						}
          }
        } catch (e) {
					setIsPostalCodeValid(false);
        } finally {
          loadingPostalCodeInfo.set(false);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [debouncePostalCode]
    );

		/**
		 * 
		 */
		const involvedCreationHandler = () => {
			const chosenPersonType = personTypes?.find(t => t.name === personType);

			if (chosenPersonType?.isThirdPartyDriver())
				setShowInvolvedCreationType(true)
			else {
				createClaimRegister();
				navigateTo(NAVIGATION_PARAMS.PERSON_EDIT);
			}
		};
		// [personType]);

		/**
     * 
     * @param damage 
     */
		const addDamage = (damage: Damage): void => {
			const damages = [...selectedDamages, damage];
			setSelectedDamages(damages);
		}
		
		/**
		 * 
		 * @param damage 
		 */
		const editDamage = (damage: Damage): void => {
			const index = selectedDamages.findIndex(d => d === damage);
			if (index > -1) {
				selectedDamages[index] = damage;
				setSelectedDamages(selectedDamages);
			}
		}

		/**
		 * 
		 * @param index 
		 */
		const removeDamage = useCallback((): void => {
			if (deleteItemIndex > -1) {
				selectedDamages.splice(deleteItemIndex, 1);
				setSelectedDamages([...selectedDamages]);
				sendCurrentAdjusterInfo();
				setShowDeleteModal(false);
				setDeleteItemIndex(-1);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [deleteItemIndex])

		/**
     * 
     * @param damage 
     * @param index 
     */
		const setDamageEdition = (damage?: Damage, index?: number): void => {
			setDamageToEdit(damage);
			setDamageIndexToEdit(index);
		}

		/**
		 * 
		 * @param service 
		 * @param index 
		 */
		const setServiceEdition = (service?: Service, index?: number): void => {
			setServiceToEdit(service);
			setServiceIndexToEdit(index);

			if (service === undefined) {
        setSelectedCoverage(undefined);
				setSelectedCategory(undefined);
				setSelectedProvider(undefined);
				setSelectedServiceType(undefined);
				setSelectedProviderState(undefined);
				setSelectedProviderType(undefined);

				form.unregister('operatorName');
				form.unregister('hasDeductible');
				form.unregister('operatorPhone');
				form.unregister('serviceAmount');
				form.unregister('serviceDescription');
				form.unregister('serviceSheetNumber');
				form.unregister('operatorCountryCode');
			} else {
				setSelectedServiceType(service.type);

				if (service.category)
					setSelectedCategory(service.category)
				
				if (service.provider && service.provider.id)
					selectProviderById(service.provider.id);

				if (service.hasDeductible !== undefined)
					form.setValue('hasDeductible', service.hasDeductible);
			}
		}

		/**
		 * 
		 */
		const syncEdua = async() => {
			try {
				loadingEDUASync.set(true);
				await synchronize();
				AlertFactory.successAlert('Los datos del eDUA se han sincronizado.');
			} catch (e) {
				console.error(e);
        AlertFactory.errorAlert((e as Error).message);
			} finally {
				loadingEDUASync.set(false);
			}
		}

		/**
		 * 
		 * @param index 
		 */
		const removeService = useCallback((): void => {
			if (registerInfo && registerInfo.services.length > 0 && deleteItemIndex > -1) {
				const services = [...registerInfo.services];
				const updatedServices = services.filter((_, i) => i !== deleteItemIndex);
				registerInfo.services = updatedServices;
				sendCurrentAdjusterInfo();
				setShowDeleteModal(false);
				setDeleteItemIndex(-1);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [deleteItemIndex])

		/**
		 * 
		 */
		const createClaimRegister = useCallback(() => {
			const chosenPersonType = personTypes?.find(t => t.name === personType)
			if (!chosenPersonType) {
				return;
			}

			let newRegister = new ClaimRegister('', '', chosenPersonType);

			if (adjusterInfo) {
				const hasNewPeople = adjusterInfo.claimPeople.find(p => p.person && p.person.id && p.person.id.length === 0);
				if (hasNewPeople === undefined) {
					adjusterInfo.addClaimPeople(newRegister)
				}
			}

			dispatch(ClaimRegisterAction.setNewClaimRegisterInfo(newRegister));
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [personType])

		/**
		 * 
		 * @param claimPerson 
		 * @returns 
		 */
		const selectClaimRegister = async(claimPerson: ClaimRegister): Promise<void> => {
			return new Promise<void>(
				(resolve, reject) => {
					try {
						dispatch(ClaimRegisterAction.setNewClaimRegisterInfo(claimPerson));
						resolve();
					} catch (e) {
						reject();
					}
				}
			)
		}

		useEffect(() => {
			if (!registerInfo || !adjusterInfo)
				return;
			
			let currentSections = [];
			const showVehicleAndDamages = (registerInfo.personType.isNA() && !adjusterInfo.claimType.isClaimRobbery()) || registerInfo.personType.isThirdPartyDriver();

			for (const section of PERSON_MANAGEMENT_SECTIONS) {
				const allowedSection = section.uid === PERSON_MANAGEMENT_SECTIONS_IDS.personal || section.uid === PERSON_MANAGEMENT_SECTIONS_IDS.services
					|| (section.uid === PERSON_MANAGEMENT_SECTIONS_IDS.vehicle && showVehicleAndDamages)
					|| (section.uid === PERSON_MANAGEMENT_SECTIONS_IDS.damages && showVehicleAndDamages)

				if (allowedSection)
					currentSections.push(section)
			}

			setPanelSections(currentSections);
		}, [registerInfo, adjusterInfo]);

		/**
		 * 
		 */
		useEffect(
			() => {
				if (registerInfo && registerInfo.personType && registerInfo.person?.profile?.address && (registerInfo.personType.isNA() || registerInfo.personType.isThirdPartyDriver())) {
					setShowUserAddress(true);
				}
			// eslint-disable-next-line react-hooks/exhaustive-deps
			}, []
		)

		/**
     * 
     */
		useEffect(
			() => {
				if (registerInfo) {
					setCurrentRegisterInfo(registerInfo);
				}
			}, [registerInfo, setCurrentRegisterInfo]
		);

		/**
		 * 
		 */
		useEffect(
			() => {
				const hasAnyPolicyData = !!registerInfo?.insuranceCompany || !!registerInfo?.thirdInsuranceDuaNumber || !!registerInfo?.thirdInsuranceClaimNumber 
					|| !!registerInfo?.policyNumber || !!registerInfo?.issueNumber;

				setShowInsuranceData(hasAnyPolicyData);
				form.setValue('hasInsurance', hasAnyPolicyData);
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [registerInfo]
		)

		/**
		 * 
		 */
		useEffect(
			() => {
				if (registerInfo?.vehicleType)
					setSelectedVehicleType(registerInfo.vehicleType);

				if (registerInfo?.insuranceCompany)
					setSelectedInsuranceCarrier(registerInfo.insuranceCompany);
			}, [registerInfo]
		);

		/**
     * 
     */
		useEffect(
			() => {
				if (debouncePostalCode !== undefined) {
					getStatesByPostalCode();
				}
			}, [debouncePostalCode, getStatesByPostalCode]
		);

		/**
		 * 
		 */
		useEffect(
			() => {
				const getInsuranceCarriers = async () => {
					const list = await ClaimRegisterFeature.providersListFeature(Util.STATUS_CODE.CLAIMS_LISTS_TYPES.PROVIDER_TYPE);
					if (list && list.length > 0)
						setInsuranceCarriersList(list);
				}

				if (!insuranceCarriersList)
					getInsuranceCarriers();
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, []
		);

		/**
		 * 
		 */
		useEffect(
			() => {
				const getProvidersList = async (serviceType: string, category?: string, claimId?: string, state?: string, type?: number) => {
					const providers = await ClaimRegisterFeature.providersListFeature(undefined, serviceType, false, category, claimId, state, type);
					const subProviders = await ClaimRegisterFeature.providersListFeature(undefined, serviceType, true, category, claimId, state, type);

					setProvidersList([])

					if (subProviders && subProviders.length > 0) {
							setProvidersList(subProviders);
							setIsSubProvider(true);
					} else if (providers && providers.length > 0)
						setProvidersList(providers);
				}

				if (selectedServiceType && registerInfo)
					getProvidersList(selectedServiceType.id, selectedCategory?.id, registerInfo.claimId, selectedProviderState, selectedProviderType);
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [selectedServiceType, selectedCategory, selectedProviderState, selectedProviderType]
		);

		/**
		 * 
		 */
		useEffect(
			() => {
				const getServiceTypesList = async () => {
					if (adjusterInfo && registerInfo) {
						const claimId = adjusterInfo.claimId;
						const personTypeId = registerInfo.personType.id;
						const liabilityId = adjusterInfo.liability?.id;
						const list = await ClaimRegisterFeature.serviceTypesFeature(claimId, personTypeId, liabilityId);
						if (list !== undefined && list.length >= 0)
							setServiceTypesList(list);
					}
				}

				if (!serviceTypesList)
					getServiceTypesList();
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [adjusterInfo, registerInfo]
		);

		/**
		 * 
		 */
		useEffect(
			() => {
				const getCoveragesList = async (incomingService: string) => {
					if (adjusterInfo && registerInfo) {
						const claimId = adjusterInfo.claimId;
						const personTypeId = registerInfo.personType.id;
						const liabilityId = adjusterInfo.liability?.id;
						const list = await ClaimRegisterFeature.coveragesListFeature(claimId, incomingService, personTypeId, liabilityId)

						if (list !== undefined && list.length >= 0) {
							setCoveragesList(list);
						}
					}
				}

				if (selectedServiceType)
					getCoveragesList(selectedServiceType.id);
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [selectedServiceType]
		);

		/**
		 * 
		 */
		useEffect(
			() => {
				const getServiceCategoriesList = async (incomingService: string) => {
					const list = await ClaimRegisterFeature.categoriesListFeature(incomingService);
					if (list !== undefined && list.length >= 0)
						setCategoriesList(list);
					else
						setSelectedCategory(undefined);
				}

				if (selectedServiceType)
					getServiceCategoriesList(selectedServiceType.id);
			}, [selectedServiceType]
		);

		/**
		 * 
		 */
		useEffect(
			() => {
				if (showUserAddress && form.getValues('savedAddress') === false) {
					form.setValue('postalCode', '');
					form.setValue('municipality', '');
					form.setValue('state', '');
				}
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [showUserAddress, form]
		)

		/**
		 * 
		 */
		useEffect(
			() => {
				const navigateToInit = () => {
					navigate(`${CLAIM_REGISTER_PATH.ADJUSTER}/${sectionName}/${formId}/${key}/person/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.PERSONS_LIST}`);
				}

				const personIndex = searchParams.get('personIndex');
				if (personIndex) {
					const index = parseInt(personIndex);
					if (index >= 0 && adjusterInfo && adjusterInfo.claimPeople.length > index) {
						const currentPerson = adjusterInfo.claimPeople[index];
						selectClaimRegister(currentPerson);
					} else {
						navigateToInit();
					}
				}
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [adjusterInfo]
		);

		useEffect(
			() => {
				if (pathname.includes(`${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.SERVICE}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LIST}`))
					setServiceEdition();

				if (pathname.includes(`${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.GENERAL}`) && !pathname.includes(`${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.EDIT}`))
					form.trigger();
				
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [pathname]
		);

		useEffect(() => {
			if (pageName !== CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.PERSON_TYPE)
				setShowInvolvedCreationType(false);
		}, [pageName])

		return { 
			adjusterInfo,
			registerInfo,
			updating,
			form,
			contentRef,
			urlParam: { pageName, formType, editPage },
			list: { countryStates, licenceTypes, personTypes, vehicleTypes, colors, insuranceCarriersList, providersList, coveragesList, bikeDamagesList, vehicleDamagesList, serviceTypesList, categoriesList },
			states: {
				showInsuranceData,
				setShowInsuranceData,
				personType,
				setPersonType,
				selectedLicenseType, 
				selectLicenceTypeById, 
				showDeleteModal,
				showPolicyDeletionModal,
				selectedInsuranceCarrier,
				isPostalCodeValid,
				setIsPostalCodeValid,
				selectedVehicleType, 
				setSelectedVehicleType,
				selectedCategory,
				selectedDamages,
				showInvolvedCreationType,
				setShowInvolvedCreationType,
				setSelectedDamages,
				currentDamage,
				setCurrentDamage, 
				currentService,
				setCurrentService,
				selectedProvider, 
				selectedColor,
				setSelectedColor,
				selectedServiceType,
				setServiceEdition,
				serviceToEdit,
				loadingEDUASync,
				removeService,
				setDeleteItemIndex,
				setShowDeleteModal,
				onDeletePolicyDataConfirm,
				setShowPolicyDeletionModal,
				setShouldDeletePolicyData,
				setSelectedServiceType,
				selectProviderStateById,
				selectInsuranceCarrierById,
				selectVehicleTypeById, 
				selectColorById,
				deletePolicyData,
				selectServiceTypeById,
				selectCoverageById,
				selectCategoryById,
				selectProviderById,
				selectProviderTypeById,
        isUpdatingForm,
				panelSections
			},
			formAddress: { showUserAddress, setShowUserAddress, onSelectAddress, loadingPostalCodeInfo, currentAddress },
			formDamages: { addDamage, removeDamage, damageToEdit, damageIndexToEdit, setDamageEdition, editDamage },
			hasOrderMessage,
			checkSectionAvailability,
			sendCurrentAdjusterInfo,
			createClaimRegister,
			selectClaimRegister,
			syncEdua,
			deleteClaimRegister,
			involvedCreationHandler,
			completeInvolvedsSection
		}
	}

	/**
	 * 
	 */
	export const useClaimPersonServiceValidations = (selectedServiceType?: StatusTypeItem, selectedServiceCategory?: StatusTypeItem) => {

		const { serviceNavigation } = useClaimPersonManagement();
		const { pathname } = useLocation();

		/**
		 * 
		 * @returns 
		 */
		const shouldDisplayCategory = (_serviceType: StatusTypeItem): boolean => {
			return (
				_serviceType.isMedicalServiceType() 
				|| _serviceType.isCraneServiceType() 
				|| _serviceType.isClaimPaymentServiceType() 
				|| _serviceType.isNoCostServiceType()
				|| _serviceType.isAssistanceServiceType()
        || _serviceType.isLawyerServiceType()
      );
		};

		/**
		 * 
		 * @param _serviceType 
		 * @returns 
		 */
		const shouldDisplayProvider = (_serviceType: StatusTypeItem, _serviceCategory?: StatusTypeItem): boolean => {
			return (
				_serviceType.isRepairServiceType()
				|| _serviceType.isMedicalServiceType()
				|| (_serviceType.isCraneServiceType() && _serviceCategory !== undefined && !_serviceCategory?.isCraneConcessionedCategory())
			);
		};

		/**
		 * 
		 * @param _serviceType 
		 * @returns 
		 */
		const shouldDisplayProviderType = (_serviceType: StatusTypeItem): boolean => {
			return _serviceType.isRepairServiceType();
		}

		/**
		 * 
		 * @param _serviceType 
		 * @returns 
		 */
		const shouldDisplayStates = (_serviceType: StatusTypeItem): boolean => {
			return (
				_serviceType.isRepairServiceType()
				|| _serviceType.isMedicalServiceType()
			);
		}

		/**
		 * 
		 * @param _serviceType 
		 * @returns 
		 */
		const shouldDisplayImport = (_serviceType: StatusTypeItem, _serviceCategory?: StatusTypeItem): boolean => {
			return (!_serviceType.isLawyerServiceType() && !_serviceType.isInvestigatorServiceType() && !_serviceType.isNoCostServiceType() && !_serviceType.isCraneServiceType() && !_serviceType.isSipacServiceType());
		}

		/**
		 * 
		 * @param _serviceType 
		 * @returns 
		 */
		const shouldDisplayCoverage = (_serviceType: StatusTypeItem): boolean => {
			return !_serviceType.isSipacServiceType();
		}

		/**
		 * 
		 * @param _serviceType 
		 * @returns 
		 */
		const shouldDisplayDeductible = (_serviceType: StatusTypeItem, _serviceCategory?: StatusTypeItem): boolean => {
			return (_serviceType.isRepairServiceType() || _serviceType.isClaimPaymentServiceType()) && (!_serviceCategory?.isAgreedPaymentServiceCategory());
		}

		/**
		 * 
		 * @param _serviceType 
		 * @returns 
		 */
		const shouldDisplayOperatorInputs = (_serviceType: StatusTypeItem): boolean => {
			return _serviceType.isCraneServiceType() || _serviceType.isLawyerServiceType();
		}

		/**
		 * 
		 * @param _serviceType 
		 * @returns 
		 */
		const shouldDisplayPictures = (_serviceType: StatusTypeItem): boolean => {
			return (
				_serviceType.isMedicalServiceType()
				|| _serviceType.isCraneServiceType()
				|| _serviceType.isContractorServiceType()
				|| _serviceType.isSipacServiceType()
				|| _serviceType.isTraditionalOrderServiceType()
			);
		}

		/**
		 * 
		 */
		useEffect(
			() => {
				if (pathname.includes(`${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.SERVICE}/${CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DATA}`) && selectedServiceType === undefined)
					serviceNavigation(SERVICE_NAVIGATION_PARAMS.LIST);
				
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [pathname, selectedServiceType]
		)

		return {
			shouldDisplayCategory,
			shouldDisplayStates,
			shouldDisplayProvider,
			shouldDisplayProviderType,
			shouldDisplayImport,
			shouldDisplayCoverage,
			shouldDisplayDeductible,
			shouldDisplayOperatorInputs,
			shouldDisplayPictures
		}
	}
}